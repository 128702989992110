import React, { useState } from 'react';
import { Container, Grid, Typography, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import PersonnelCard from './PersonnelCard';

const doctors = [
  {
    name: "Dr. Almeida Duarte",
    specialty: "Cardiologia",
    image: 'https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80',
    link: "#dr-almeida-duarte"
  },
  {
    name: "Dr. António Pinto",
    specialty: "Cardiologia",
    image: 'https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80',
    link: "#dr-antonio-pinto"
  },
  {
    name: "Dra. Fátima Veiga",
    specialty: "Cardiologia",
    image: 'https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80',
    link: "#dra-fatima-veiga"
  },
  {
    name: "Dra. Isabel Menezes",
    specialty: "Cardiologia Pediátrica",
    image: 'https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80',
    link: "#dra-isabel-menezes"
  }
];

const specialities = ["Todas", "Cardiologia", "Cardiologia Pediátrica"];

const TeamList = () => {
  const [selectedSpeciality, setSelectedSpeciality] = useState("Todas");

  const handleSpecialityChange = (event) => {
    setSelectedSpeciality(event.target.value);
  };

  const handleCardClick = (link) => {
    window.location.href = link;
  };

  const filteredDoctors = selectedSpeciality === "Todas" ? doctors : doctors.filter(doctor => doctor.specialty === selectedSpeciality);

  return (
    <Container maxWidth={false} sx={{ width: '100%', padding: '16px' }}>
      <Box sx={{ width: '100%' }}>
        <FormControl fullWidth margin="normal" sx={{ width: '100%' }}>
          <InputLabel>Especialidade</InputLabel>
          <Select
            value={selectedSpeciality}
            onChange={handleSpecialityChange}
          >
            {specialities.map((speciality, index) => (
              <MenuItem key={index} value={speciality}>{speciality}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {selectedSpeciality !== "Todas" && (
        <Box my={4}>
          <Typography variant="h5" textAlign="left">
            {selectedSpeciality}
          </Typography>
        </Box>
      )}
      <Grid container spacing={4}>
        {filteredDoctors.map((doctor, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <PersonnelCard doctor={doctor} onCardClick={handleCardClick} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TeamList;
