import React from 'react';
import SpecialitiesPreview from '../components/speciality-preview/SpecialitiesPreview';

export default function SpecialityHome() {
  return (
    <>
      <h1 className='specialityhome'>SpecialityHome</h1>
      <SpecialitiesPreview />
    </>
  );
}