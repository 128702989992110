import React from 'react';
import ImageSlider from '../components/image-slider/ResponsiveSlider'
import AppointmentCards from '../components/appointment-cards/AppointmentCards';
import FooterLegal from '../components/footer-legal-faq/Footer';
import MotivationCard from '../components/mission/MotivationCard';
import ChooseUs from '../components/team-recommendation/ChooseUs';

export default function Home() {
  return (
    <>
      <ImageSlider/>
      <MotivationCard />
      <AppointmentCards/>
      <ChooseUs/>
      <FooterLegal/>
    </>
  );
}