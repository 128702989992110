// src/components/Location.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import './Location.css';

const Location = () => {
  const handleMapClick = () => {
    window.open('https://www.google.com/maps/place/Alameda+São+Vicente+Ferrer+n.º+167,+São+Félix+da+Marinha,+4410-104+Vila+Nova+de+Gaia,+Portugal', '_blank');
  };

  return (
    <Box className="location" onClick={handleMapClick} sx={{ cursor: 'pointer' }}>
      <Typography variant="h5" gutterBottom>Our Location</Typography>
      <img src={`${process.env.PUBLIC_URL}/mapa.png`} alt="Map Location" className="map-image" />
    </Box>
  );
};

export default Location;
