import React from 'react';
import SpaceImagesText from '../components/space-overview/SpaceImagesText'

export default function Manustuas() {
  return (
    <>
      <SpaceImagesText
       image="https://images.unsplash.com/photo-1546768292-fb12f6c92568?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
       title="Salas Psicológia"
       description="Sed porttitor purus et nisi egestas, quis sagittis orci rhoncus. Phasellus tristique risus a sapien maximus tempus. Fusce sagittis, quam quis viverra convallis, ex nibh venenatis nunc, ut condimentum elit ante id diam. Aliquam porta finibus mi. Donec pellentesque porta justo, vitae pulvinar mi ultrices in. Curabitur et dolor at justo bibendum eleifend id molestie massa"
       orientation="right"
       />
       <SpaceImagesText
       image="https://images.unsplash.com/photo-1546768292-fb12f6c92568?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
       title="Salas Psicológia 2"
       description="Sed porttitor purus et nisi egestas, quis sagittis orci rhoncus. Phasellus tristique risus a sapien maximus tempus. Fusce sagittis, quam quis viverra convallis, ex nibh venenatis nunc, ut condimentum elit ante id diam. Aliquam porta finibus mi. Donec pellentesque porta justo, vitae pulvinar mi ultrices in. Curabitur et dolor at justo bibendum eleifend id molestie massa"
       orientation="left"/>
    </>
  );
}