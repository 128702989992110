import React from 'react';
import TeamList from '../components/team-information/TeamList';
import FooterLegal from '../components/footer-legal-faq/Footer';

export default function Team() {
  return (
    <div>
    <div style={{ width: '100%' }}>
    <TeamList />
    </div>
    <FooterLegal/>
    </div>
  );
}