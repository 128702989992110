// src/components/Banner.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import './VerticalInformationBanner.css';

const VerticalInformationBanner = () => {
  return (
    <Box className="banner" p={2}>
      <Typography variant="h5" gutterBottom>Horário</Typography>
      <Typography>Segunda a Sexta-Feira</Typography>
      <Typography>8h00 às 20h00</Typography>
      <Typography>Sábado, Domingo e Feriados</Typography>
      <Typography>Encerrados</Typography>
      <br />
      <Typography variant="h5" gutterBottom>Morada</Typography>
      <Typography>Alameda São Vicente Ferrer n.º 167,</Typography>
      <Typography>São Félix da Marinha, 4410-104 Vila Nova de Gaia, Portugal</Typography>
      <br />
      <Typography variant="h5" gutterBottom>Contactos</Typography>
      <Typography>+351 915 108 204*</Typography>
      <Typography>+351 224 045 919**</Typography>
      <Typography>*Chamada para rede móvel nacional</Typography>
      <Typography>**Chamada para rede fixa nacional</Typography>
      <br />
      <Typography variant="h5" gutterBottom>Email</Typography>
      <Typography>geral@fisioglobal.pt</Typography>
    </Box>
  );
};

export default VerticalInformationBanner;
