// MotivationCard.js
import React from 'react';
import './MotivationCard.css';
import { FaHandsHelping } from 'react-icons/fa';
import { 
  Typography, 
  Grid, 
  Paper, 
  Box, 
  useTheme,
  useMediaQuery,
  Divider
} from '@mui/material';

const MotivationCard = () => {
  return (
    <div className="image-card">
      <div className="text-content">
      <Typography 
  variant="h3"
  component="h4"
  align="start"
  sx={{
    fontWeight: 600,
    color: 'black',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontSize: { xs: '1rem', sm: '2rem', md: '3rem' },
  }}
>
          A sua clínica de saude e
          educação em Almada.
        </Typography>
        <Typography variant="body2" sx={{ py: 2,pr: 3, color: 'rgba(0, 0, 0, 0.6)', fontWeight: '500' }}>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
          doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore 
          veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam 
          voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
          magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam 
          est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia 
          non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam 
          quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem 
          ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? 
          Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil 
          molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla 
          pariatur?
        </Typography>
        <button className="team-button">conhecer a equipa</button>
      </div>
      <div className="motivational-image">
      <img src={`${process.env.PUBLIC_URL}/mission.jpg`} alt="Motivational" />
      </div>
    </div>
  );
};

export default MotivationCard;