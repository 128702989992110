// src/Footer.js
import React from 'react';
import { Box, Grid, Typography, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  const links = [
    "Contactos",
    "Canal de Denúncia",
    "Elogios, Sugestões e Reclamações",
    "Aviso Legal",
    "Proteção de dados",
    "Gerir cookies",
  ];

  return (
    <Box component="footer" sx={{ bgcolor: '#f5f5f5', p: 3 }}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm="auto" container justifyContent={{ xs: 'center', sm: 'flex-start' }}>
          <Box
            component="img"
            sx={{
              height: 40,
              width: 'auto',
            }}
            alt="Logo"
            src={`${process.env.PUBLIC_URL}/logo_horizontal.png`}
          />
        </Grid>
        <Grid item xs={12} sm>
          <Grid container justifyContent={{ xs: 'center', sm: 'center' }} spacing={1}>
            {links.map((link, index) => (
              <Grid item key={index}>
                <Link href="#" underline="none" color="textSecondary">
                  {link}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm="auto" container justifyContent={{ xs: 'center', sm: 'flex-end' }}>
          <IconButton href="#" color="inherit">
            <FacebookIcon />
          </IconButton>
          <IconButton href="#" color="inherit">
            <InstagramIcon />
          </IconButton>
          <IconButton href="#" color="inherit">
            <YouTubeIcon />
          </IconButton>
          <IconButton href="#" color="inherit">
            <LinkedInIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary" align="center">
            2024 © ManusTuas - Centro de Saúde e Educação | Desenvolvido por Pedro Cruz | Design e Multimédia por Carolina D'Oliveira
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
