import React from 'react';
import { Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';

const CardWrapper = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`;

const PersonnelCard = ({ doctor, onCardClick }) => {
  return (
    <CardWrapper onClick={() => onCardClick(doctor.link)}>
      <CardMedia
        component="img"
        image={doctor.image}
        alt={doctor.name}
        sx={{ borderRadius: '50%', width: '60%', paddingTop: '20px' }}
      />
      <CardContent>
        <Typography variant="h6" component="div" textAlign="center">
          {doctor.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" textAlign="center">
          {doctor.specialty}
        </Typography>
      </CardContent>
    </CardWrapper>
  );
};

export default PersonnelCard;
