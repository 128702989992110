import React from 'react';
import SpecialityTemplate from '../../components/speciality-specific/SpecialityTemplate'

export default function Speciality1() {
  return (
    <>
     <SpecialityTemplate
       title="Clinica Geral"
       description="Sed porttitor purus et nisi egestas, quis sagittis orci rhoncus. Phasellus tristique risus a sapien maximus tempus. Fusce sagittis, quam quis viverra convallis, ex nibh venenatis nunc, ut condimentum elit ante id diam. Aliquam porta finibus mi. Donec pellentesque porta justo, vitae pulvinar mi ultrices in. Curabitur et dolor at justo bibendum eleifend id molestie massa"
       image="https://images.unsplash.com/photo-1546768292-fb12f6c92568?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
       professionals ={[
        {
          name: "Dr. Almeida Duarte",
          specialty: "Cardiologia",
          image: 'https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80',
          link: "#dr-almeida-duarte"
        },
        {
          name: "Dr. António Pinto",
          specialty: "Cardiologia",
          image: 'https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80',
          link: "#dr-antonio-pinto"
        },
        {
          name: "Dra. Fátima Veiga",
          specialty: "Cardiologia",
          image: 'https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80',
          link: "#dra-fatima-veiga"
        },
        {
          name: "Dra. Isabel Menezes",
          specialty: "Cardiologia Pediátrica",
          image: 'https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80',
          link: "#dra-isabel-menezes"
        }
      ]}
       />
    </>
  );
}