import React from 'react';
import { Container, Grid } from '@mui/material';
import FAQ from '../components/user-information/FAQ';
import Banner from '../components/user-information/VerticalInformationBanner';
import Location from '../components/user-information/Location';
import FooterLegal from '../components/footer-legal-faq/Footer';

export default function Products() {
  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <FAQ />
          </Grid>
          <Grid item xs={12} md={4}>
            <Banner />
          </Grid>
          <Grid item xs={12}>
            <Location />
          </Grid>
        </Grid>
      </Container>
      <FooterLegal />
    </>
  );
}