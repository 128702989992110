// Items for the dropdown menu
export const MenuItems = [
    {
      title: 'Especialidade1',
      path: '/especialidade1',
      cName: 'dropdown-link'
    },
    {
      title: 'Especialidade2',
      path: '/especialidade2',
      cName: 'dropdown-link'
    },
    {
      title: 'Especialidade3',
      path: '/especialidade3',
      cName: 'dropdown-link'
    },
    {
      title: 'Especialidade4',
      path: '/especialidade4',
      cName: 'dropdown-link'
    },
  ]