// src/AppointmentCards.js
import React from 'react';
import { Box, Typography, Link, Grid } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './AppointmentCards.css';

const AppointmentCards = () => {
  const cards = [
    {
      title: "Consulta Por Telefone",
      description: "Ligue e agende sua consulta em minutos. Simples e eficiente.",
      linkText: "+351 239 802 700",
      info: "(Chamada para rede fixa nacional)"
    },
    {
      title: "Consulta Online",
      description: "Marque a sua consulta com toda a facilidade e receba rapidamente no seu email a confirmação.",
      linkText: "Marcar Consulta",
    },
    {
      title: "Consulta ao Domicilio",
      description: "Marque o seu exame com toda a facilidade e receba rapidamente no seu email a confirmação.",
      linkText: "Marcar Exame",
    },
  ];

  return (
    <Box className="appointment-section">
      <Typography variant="h3" component="h4" className="section-header" sx={{
    fontWeight: 400,
    color: 'black',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontSize: { xs: '1rem', sm: '2rem', md: '3rem' },
  }}>
        Marcação de Consultas:
      </Typography>
      <Box className="appointment-container">
        <Grid container spacing={4}>
          {cards.map((card, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box className="appointment-card">
                <Typography variant="h4" component="h2" className="card-title">
                  {card.title}
                </Typography>
                <Typography variant="body1" className="card-description">
                  {card.description}
                </Typography>
                <Link href="#" className="card-link">
  <span className="card-link-text">{card.linkText}</span>
  <ArrowForwardIosIcon fontSize="small" />
</Link>
                {card.info && (
                  <Typography variant="caption" className="card-info">
                    {card.info}
                  </Typography>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AppointmentCards;