import React from 'react';
import { 
  Typography, 
  Grid, 
  Paper, 
  Box, 
  useTheme,
  useMediaQuery,
  Divider
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupsIcon from '@mui/icons-material/Groups';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import RecyclingIcon from '@mui/icons-material/Recycling';
import InsightsIcon from '@mui/icons-material/Insights';
import './ChooseUs.css';

const Feature = ({ icon, title, description }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid item xs={12} sm={6} md={6} lg={3}>
      <Paper
        elevation={3}
        sx={{
          background: '#ffffff',
          borderRadius: '18px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          height: '100%',
          p: isSmallScreen ? 1 : 2,
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center" height="100%">
          {React.cloneElement(icon, { sx: { fontSize: isSmallScreen ? 30 : 40 } })}
          <Typography 
            variant={isSmallScreen ? "body1" : "h6"} 
            component="h3" 
            gutterBottom 
            align="center" 
            sx={{ 
              mt: 1,
              fontWeight: '500',
              color: '#000000'
            }}
          >
            {title}
          </Typography>
          <Typography variant="body2" align="center" sx={{color: '#86868b', fontSize: isSmallScreen ? '0.8rem' : '1rem' }}>
            {description}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );
};

const MobileFeature = ({ icon, title, description }) => {
  return (
    <Box mb={4}>
      <Box display="flex" alignItems="center" mb={1}>
        {icon}
        <Typography variant="h6" component="h3" ml={0.75} fontWeight="bold" color={'black'}>
          {title}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ pr: 3, color: 'rgba(0, 0, 0, 0.6)', fontWeight: '500' }}>{description}</Typography>
      <Divider sx={{ mt: 2 }} />
    </Box>
  );
};

const ChooseUs = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Adjust the breakpoint

  const features = [
    {
      icon: <GroupsIcon />,
      title: "Proximidade:",
      description: "Proporcionamos um ambiente acolhedor, familiar e uma equipa na qual pode confiar cuja missão é traçar um plano de intervenção ajustado as suas necessidades."
    },
    {
      icon: <EmojiEventsIcon />,
      title: "Razao 2:",
      description: "Quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse."
    },
    {
      icon: <InsightsIcon />,
      title: "Evolução:",
      description: "Apostamos na monotorização regular e intensiva, de análise e partilha de resultados consigo."
    },
    {
      icon: <RecyclingIcon />,
      title: "Sustentibilidade:",
      description: "Implementamos em todos os passos das nossa operação medidas que tem por base aumentar a nossa eficiencia na utilizacao de recursos bem como diminuir a nossa pegada ecológica."
    }
  ];

  return (
    <Box 
      sx={{ 
        width: '96.5%',
        px: { xs: 2, sm: 2, md: 3 },
        py: 4,
        backgroundColor: '#f5f5f7'
      }}
    >
      <Typography 
  variant="h3"
  component="h4"
  align="start"
  sx={{
    fontWeight: 600,
    color: 'black',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontSize: { xs: '1rem', sm: '2rem', md: '3rem' },
  }}
>
  Existe algo que nos distingue.
</Typography>
      
      {isSmallScreen ? (
        <Box>
          {features.map((feature, index) => (
            <MobileFeature key={index} {...feature} />
          ))}
        </Box>
      ) : (
        <Box sx={{ mt: { xs: 3, sm: 4, md: 2 } }}>
        <Grid container spacing={2}>
          {features.map((feature, index) => (
            <Feature key={index} {...feature} />
          ))}
        </Grid>
        </Box>
      )}
    </Box>
  );
};

export default ChooseUs;
