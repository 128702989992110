import React from 'react';
import { Box, Card, CardMedia, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ orientation }) => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  maxWidth: 800,
  margin: 'auto',
  overflow: 'visible',
  borderRadius: '8px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  flexDirection: orientation === 'left' ? 'row-reverse' : 'row',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
  '@media (max-width: 960px)': {
    flexDirection: 'column',
  },
}));

const ImageContainer = styled(Box)({
  width: '100%',
});

const StyledCardMedia = styled(CardMedia)({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const ContentBox = styled(Box)(({ orientation }) => ({
  position: 'absolute',
  top: '15%',
  [orientation === 'left' ? 'left' : 'right']: '-20%',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  padding: '24px',
  borderRadius: '8px',
  maxWidth: '50%',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  '@media (max-width: 696px)': {
    maxWidth: '100%',
    [orientation === 'left' ? 'left' : 'right']: '0%',
    position: 'relative',
    backgroundColor: 'white',
  },
  '@media (max-width: 960px)': {
    maxWidth: '100%',
    [orientation === 'left' ? 'left' : 'right']: '0%',
    position: 'relative',
    backgroundColor: 'white',
  },
}));

const StyledButton = styled(Button)({
  marginTop: '16px',
});

function RoomBookingCard({ image, title, description, orientation = 'right' }) {
  return (
    <StyledCard orientation={orientation}>
      <ImageContainer>
        <StyledCardMedia
          component="img"
          image={image}
          alt={title}
        />
      </ImageContainer>
      <ContentBox orientation={orientation}>
        <Typography variant="h5" component="div" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {description}
        </Typography>
      </ContentBox>
    </StyledCard>
  );
}

export default RoomBookingCard;
