// src/FAQ.js
import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './FAQ.css';

const faqs = [
  {
    question: "Possuem acordos com seguradoras e/ou subsistemas de saúde?",
    answer: "Sim, possuímos acordos com diversas seguradoras e subsistemas de saúde.",
  },
  {
    question: "Possuem acordo com o Serviço Nacional de Saúde (SNS)?",
    answer: "Sim, possuímos acordo com o SNS.",
  },
  {
    question: "Realizam consultas de urgência?",
    answer: "Sim, realizamos consultas de urgência.",
  },
  {
    question: "Que meios de pagamento têm disponíveis?",
    answer: "Aceitamos diversos meios de pagamento como cartão de crédito, débito, e transferências bancárias.",
  },
  {
    question: "Que especialidade estão disponíveis para teleconsulta?",
    answer: "Disponibilizamos várias especialidades para teleconsulta, incluindo clínica geral, pediatria, e mais.",
  },
  {
    question: "Que especialidade estão disponíveis para teleconsulta?",
    answer: "Disponibilizamos várias especialidades para teleconsulta, incluindo clínica geral, pediatria, e mais.",
  },
  {
    question: "Que especialidade estão disponíveis para teleconsulta?",
    answer: "Disponibilizamos várias especialidades para teleconsulta, incluindo clínica geral, pediatria, e mais.",
  },
  {
    question: "Que especialidade estão disponíveis para teleconsulta?",
    answer: "Disponibilizamos várias especialidades para teleconsulta, incluindo clínica geral, pediatria, e mais.",
  },
  {
    question: "Que especialidade estão disponíveis para teleconsulta?",
    answer: "Disponibilizamos várias especialidades para teleconsulta, incluindo clínica geral, pediatria, e mais.",
  },
  {
    question: "Que especialidade estão disponíveis para teleconsulta?",
    answer: "Disponibilizamos várias especialidades para teleconsulta, incluindo clínica geral, pediatria, e mais.",
  }
];

const FAQ = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="faq">
      <Typography variant="h4" gutterBottom>Perguntas frequentes</Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FAQ;
