import React from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography, Link } from '@mui/material';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

const services = [
  {
    title: "Fisioterapia Músculo-Esquelética",
    description: "A Fisioterapia Músculo-Esquelética visa a prevenção, avaliação, tratamento e potenciação de condições do sistema muscular, esquelético e nervoso.",
    image:         'https://images.unsplash.com/photo-1546768292-fb12f6c92568?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
    ,
    link: "#"
  },
  {
    title: "Osteopatia",
    description: "Através de técnicas de manipulação não-invasivas e sem a utilização de medicação, a osteopatia visa equilibrar e melhorar a saúde de todos os sistemas do corpo.",
    image:         'https://images.unsplash.com/photo-1546768292-fb12f6c92568?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
    ,
    link: "#"
  },
  {
    title: "Exercício",
    description: "Num contexto em que a maioria das pessoas vive uma vida stressante e sedentária, a prática de exercício físico é uma componente fundamental e terapêutica do dia-a-dia.",
    image:         'https://images.unsplash.com/photo-1546768292-fb12f6c92568?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
    ,
    link: "#"
  }
];

const ServiceCard = ({ service }) => (
  <Card>
    <CardMedia
      component="img"
      height="140"
      image={service.image}
      alt={service.title}
      style={{ objectFit: 'cover' }}
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        {service.title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {service.description}
      </Typography>
      <Link href={service.link} variant="body2">
        Ler mais...
      </Link>
    </CardContent>
  </Card>
);

let theme = createTheme();
theme = responsiveFontSizes(theme);

const SpecialitiesPreview = () => (
  <ThemeProvider theme={theme}>
    <Container>
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ServiceCard service={service} />
          </Grid>
        ))}
      </Grid>
    </Container>
  </ThemeProvider>
);

export default SpecialitiesPreview;
