// src/components/ModalityTemplate.js
import React from 'react';
import { Container, Grid, Typography, Box, Card, CardContent, CardMedia } from '@mui/material';
import './SpecialityTemplate.css';
import PersonnelCard from '../team-information/PersonnelCard';

const SpecialityTemplate = ({ title, description, image, professionals }) => {
  return (
    <Container>
      <Box className="header" sx={{ backgroundColor: '#ffffff', padding: '20px' }}>
        <Typography variant="h3" sx={{ color: '#000000' }}>{title}</Typography>
      </Box>
      <Grid container spacing={3} sx={{ marginTop: '20px' }}>
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            alt={title}
            height="auto"
            image={image}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>{title}</Typography>
          <Typography variant="body1">{description}</Typography>
        <button className="team-button">Marque a sua Consulta</button>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: '40px' }}>
        <Typography variant="h4" gutterBottom>Os nossos profissionais</Typography>
      </Box>
      <Grid container spacing={4}>
        {professionals.map((doctor, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <PersonnelCard doctor={doctor}/>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SpecialityTemplate;
